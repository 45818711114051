/*@font-face {
  font-family: "semibol";
  src: url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/medium/geomanist-medium-webfont.eot");
  src: url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/medium/geomanist-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/medium/geomanist-medium-webfont.woff2") format("woff2"),
    url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/medium/geomanist-medium-webfont.woff") format("woff"),
    url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/medium/geomanist-medium-webfont.ttf") format("truetype"),
    url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/medium/geomanist-medium-webfont.svg#geomanistregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "geomanistregular";
  src: url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/regular/geomanist-regular-webfont.eot");
  src: url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/regular/geomanist-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/regular/geomanist-regular-webfont.woff2")
      format("woff2"),
    url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/regular/geomanist-regular-webfont.woff")
      format("woff"),
    url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/regular/geomanist-regular-webfont.ttf")
      format("truetype"),
    url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/regular/geomanist-regular-webfont.svg#geomanistregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
  font-family: "regular";
  src: url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/popins/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "medium";
  src: url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/popins/Poppins-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bold";
  src: url("https://joyqr.sfo3.digitaloceanspaces.com/fonts/popins/Poppins-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}